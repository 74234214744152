@use 'common'

$-tableBorderColor: #EBEBEB

.wrapper
	//

	&.is_withInlineLink
		.is_wysiwyg
			a
				color: inherit

				&:hover, &:focus-visible
					text-decoration: none

.section

	// @TODO: adjust spacing
	&:not(.is_wysiwyg) + &,
	& + &:not(.is_wysiwyg)
		margin-top: var(--content-gap-default)

	$referencesAtLastPositionWithNoGaps: ".is_reference_asideMediaContent"
	$referencesAtLastPositionWithSmallGaps: ".is_reference_hideContentBelow"

	&.is_wysiwyg + &:not(.is_wysiwyg),
	&:not(.is_wysiwyg) + &.is_wysiwyg,
	&.is_wysiwyg + &.is_wysiwyg
		margin-top: var(--content-gap-small)

		&:is(#{$referencesAtLastPositionWithSmallGaps})
			padding-bottom: var(--content-gap-small)

	&:last-child
		&:not(#{$referencesAtLastPositionWithNoGaps})
			padding-bottom: var(--content-gap-default)

	[data-contember-type="table"]
		border: 0.125rem solid $-tableBorderColor
		border-spacing: 0
		border-collapse: collapse

	[data-contember-type="tableRow"]
		//

	[data-contember-type="tableCell"]
		padding: 0.9375em
		border: 0.125rem solid $-tableBorderColor

	[data-contember-type="scrollTarget"]
		scroll-margin-top: 9.375rem

.notImplemented
	border: 0.0625rem solid
	background-color: rgba(#ff0000, 0.07)
	color: #ff0000
	padding: 1em
	text-align: center

	&_name
		font-size: 2em
		text-transform: capitalize
