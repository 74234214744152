@use 'common'

.title
    font-size: 2.125rem
    line-height: 1.15
    margin-block: 0

    @media not all and (min-width: common.$break48)
        &.is_bigger
            font-size: 3.125rem
            line-height: 1

    @media (min-width: common.$break48)
        font-size: 3.125em
        line-height: 0.825

    @media (min-width: common.$break62)
        font-size: 5em

        &.is_biggerLineHeight
            line-height: 0.95
.text
    font-size: 1.5em
    line-height: 1.13

    &.is_bigger
        font-size: 1.875em

    p
        margin-bottom: 0
        margin-top: 1.25rem

        @media (min-width: common.$break48)
            margin-top: 2.5rem
