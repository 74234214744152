@use 'common'
@use 'sass:math'

.wrapper
	--MemberList-itemGap: 1rem
	display: flex
	flex-direction: column
	gap: var(--MemberList-itemGap)
	list-style-type: none
	padding-left: 0
	font-size: 1.125rem

	@media (min-width: common.$break48)
		--MemberList-itemGap: 1.5rem

.item
	display: flex
	align-items: center
	gap: 2.5rem

	@media not all and (min-width: common.$break48)
		gap: 1.25rem

.imageWrapper
	width: 40%
	position: relative
	flex-shrink: 0
	aspect-ratio: 1
	overflow: hidden
	border-radius: 50%

	@media (min-width: common.$break30)
		width: math.div(100%, 3)

img.image
	position: absolute
	inset: 0
	height: 100%
	object-fit: cover
	object-position: center

.content
	flex-grow: 1

.name
	margin-block: 0
	color: common.$color-yellow
	font-size: 1.25rem
	font-weight: 700

.position
	margin-top: 0.25em
	margin-bottom: 0
	font-weight: 500

.description
	margin-top: 1em
