@use 'common'

.wrapper
	//

.link
	display: flex
	flex-direction: column
	align-items: center
	text-decoration: unset

.icon
	display: flex
	width: 4.6875rem
	height: auto
	margin: auto

.name
	font-size: 1.125em
	line-height: 1.22
