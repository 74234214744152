@use 'common'

.list
	display: grid
	grid-template-columns: 1fr 1fr 1fr
	gap: 1.25rem

	@media (min-width: common.$break48)
		gap: 2.1875rem

.item
	//
