@use 'common'
@use 'sass:color'

.wrapper
	width: 100%
	height: 100%
	position: relative
	z-index: 0
	border-radius: 1.25rem
	overflow: hidden

	&::before
		content: ''
		display: block
		width: 100%
		padding-top: calc(var(--Video-height) / (var(--Video-width)) * 100%)

.video, .poster
	position: absolute
	inset: 0
	width: 100%
	height: 100%

.poster
	border: unset
	background: transparent
	z-index: 1
	transition-property: opacity, visibility
	transition-duration: .2s

	&.is_hidden
		opacity: 0
		visibility: hidden

	&:hover, &:focus-visible
		.playButton
			border-left-color: black

.video
	z-index: 0

.playButton
	--Video-playButton-cathetus: 1.25rem
	--Video-playButton-hypotenus: 2.5rem

	position: absolute
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	border-style: solid
	border-width: var(--Video-playButton-cathetus) 0 var(--Video-playButton-cathetus) var(--Video-playButton-hypotenus)
	border-color: transparent transparent transparent common.$color-white
	transition: border-left-color .2s
	z-index: 1

	@media (min-width: common.$break48)
		--Video-playButton-cathetus: 1.875rem
		--Video-playButton-hypotenus: 3.75rem
