@use 'common'
@use 'sass:color'

$padding: .625rem

.wrapper

    &.is_rangeDate
        flex-direction: column
        align-items: flex-start

        @media (min-width: common.$break48)
            flex-direction: initial

    &.is_select
        //

    &.is_focused, input:focus-visible
        outline: unset
        background-color: common.$color-grey-2

    &.is_error
        //

.label
    display: flex
    align-items: center
    gap: .625rem
    width: 100%
    padding: .625rem
    color: common.$color-white
    font-size: var(--content-fontSize)

    &Text
        font-weight: 500
        flex-shrink: 0

.errors
    flex-shrink: 0

    .alert
        margin: 0
        padding: 0 $padding $padding
        font-size: 14px
        color: common.$color-red
        font-weight: 500

.datePicker

    &Input
        border: unset
        background-color: unset
        width: fit-content
        width: 100%
        height: 100%
        font-size: inherit
        font-family: sans-serif

    &Calendar
        //

.select
    width: 100%
    color: common.$color-white

    @media (min-width: common.$break62)
        margin: auto

    :global
        .select__control
            display: flex
            overflow: hidden
            border: unset
            background-color: common.$color-grey
            cursor: pointer
            caret-color: inherit

            &:hover, &--is-focused
                cursor: pointer
                border: unset
                box-shadow: unset

        .select__placeholder
            font-size: 18px
            color: common.$color-white
            opacity: .5

        .select__menu
            &-list
                color: common.$color-white
                background-color: common.$color-grey

        .select__input
            &-container
                color: inherit

        .select__single
            &-value
                color: inherit

        .select__option

            &--is-selected
                background-color: common.$color-grey

            &:hover
                background-color: color.adjust(common.$color-grey, $lightness: 30%)
