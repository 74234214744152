@use 'common'
@use 'sass:color'

.localeLabel
	display: flex
	align-items: center
	gap: .625rem
	text-transform: uppercase

.flagImage
	max-width: 1.25rem
	height: auto

.select
	width: 100%
	margin: 0 auto

	@media (min-width: common.$break62)
		margin: auto

	:global
		.select__control
			display: flex
			overflow: hidden
			border: 1px solid common.$color-grey
			background-color: common.$color-grey
			cursor: pointer
			caret-color: inherit
			color: common.$color-text

			&:hover, &--is-focused
				cursor: pointer
				box-shadow: unset

			&--is-focused
				.select__placeholder
					opacity: .4

		.select__placeholder
			font-size: 18px

		.select__input-container,
		.select__single-value
			color: currentColor

		.select__menu
			background-color: common.$color-grey-2
			margin-bottom: 0

		.select__option
			z-index: 0
			color: currentColor

			&--is-selected
				background-color: common.$color-yellow

			&:hover
				background-color: color.adjust(common.$color-yellow, $lightness: 30%)
