@use 'common'

.wrapper
	display: flex
	flex-direction: column
	min-height: 100vh

	&:not(.view_generic) .main
		padding-bottom: var(--content-gap-small)

.header

	&.is_overContent
		position: absolute
		top: 0
		left: 0
		right: 0
		z-index: 1

.main
	flex-grow: 1

.footer
	//
