@use 'common'

.wrapper
    position: relative
    z-index: 0

.gradient

    &::after
        content: ''
        position: absolute
        right: 0
        bottom: 100%
        left: 0
        height: 8.125rem
        background: linear-gradient(to bottom, transparent 0%, black 100%)

.content
    display: flex
    align-items: center
    flex-direction: column
    gap: 1.25rem
    margin-top: 2.5rem
    text-align: center

.appBanner
    //
