@use "../variables" as var
@use "sass:color"

=button
    display: inline-block
    background-color: var.$color-yellow
    color: var.$color-black
    font-size: 1.125em
    font-weight: 500
    border-radius: 5rem
    padding: .625rem 2.5rem
    text-decoration: none
    transition: background-color .2s

    &:hover, &:focus-visible
        background-color: color.adjust(var.$color-yellow, $lightness: 10%)

    @media (min-width: var.$break48)
        font-size: 1.5em
