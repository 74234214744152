@use 'common'

.wrapper
	width: 100%
	background-color: transparent
	border: unset
	padding: unset
	font-size: inherit

	&:focus-visible
		outline: unset
