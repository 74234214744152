@use 'common'

.wrapper
	//

.content

	&.is_appVisible
		display: flex
		justify-content: space-between
		flex-wrap: wrap

		.title, .apps
			@media (min-width: common.$break30)
				width: 50%

		.text
			@media (min-width: common.$break30)
				order: 1

		.text
			@media not all and (min-width: common.$break30)
				margin-bottom: 1rem

		@media (min-width: common.$break48)
			width: auto

.title
	//

.text
	//

.title, .text
	@media (min-width: common.$break48)
		max-width: 33.75rem
		margin-right: auto

.apps
	display: flex
	align-items: flex-end
	flex-wrap: wrap
	gap: .75rem
	width: 100%

	@media (min-width: common.$break30)
		flex-direction: column

.app
	font-size: 2.375rem

	@media (min-width: 23rem)
		font-size: 2.8125rem

	@media (min-width: common.$break62)
		font-size: 4.0625rem

.media
	margin-top: 2.8125rem

.gallery
	@media not all and (min-width: common.$break30)
		display: none

.image

	&.is_mobile
		@media (min-width: common.$break30)
			display: none

	@media (min-width: common.$break48)
		margin-top: 5.625rem
