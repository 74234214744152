@use 'common'
@use 'sass:color'

.wrapper
	background-color: common.$color-grey-2
	color: common.$color-white

.main

	&In
		display: flex
		flex-direction: column
		justify-content: space-between
		gap: 1.875rem
		padding-block: 3.75rem
		font-size: 1rem

		@media (min-width: common.$break48)
			font-size: 1.125rem
			gap: 3.75rem

		@media (min-width: common.$break48)
			flex-direction: row

		@media (min-width: common.$break62)
			gap: 6.25rem

.logo
	display: flex
	position: relative
	z-index: 0
	aspect-ratio: calc(var(--Footer-logo-width) / var(--Footer-logo-height))
	width: calc(var(--Footer-logo-width) * 1px)

	&In
		//

.link
	color: inherit

	&List
		list-style-type: none
		padding: unset
		margin: unset

		display: flex
		flex-direction: column
		gap: .5rem

	&Item

		&Link
			text-decoration: underline
			color: inherit
			line-height: 1.66
			font-size: inherit
			font-weight: 400
			padding: 0
			border-style: none
			background-color: transparent
			font-family: inherit

			&:hover, &:focus-visible
				text-decoration: none

.column
	display: flex
	flex-direction: column
	gap: 1.375rem
	line-height: 1.22

	&:first-child
		justify-content: space-between

	@media (min-width: common.$break48)
		&:nth-child(2)
			margin-left: auto

.addressInfo
	font-style: initial
	font-size: inherit

	p
		margin: unset

.contact
	&Link
		color: inherit

		&:hover, &:focus-visible
			text-decoration: none

.locales
	max-width: 7.5rem

	&.is_desktop
		@media not all and (min-width: common.$break48)
			display: none

	&.is_mobile
		@media (min-width: common.$break48)
			display: none

	@media (min-width: common.$break62)
		margin-inline: initial
