@use 'common'

.wrapper
    display: grid
    grid-template-columns: 1fr
    gap: 2.5rem

    @media (min-width: common.$break30)
        grid-template-columns: 1fr 1fr

    @media (min-width: common.$break64)
        gap: 3.75rem

.item
    display: flex
    justify-content: center
    align-items: center

.image
    max-width: 100%
    height: auto
