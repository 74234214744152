@use 'common'
@use 'sass:math'

.wrapper
	display: inline-block
	line-height: 1.25
	font-weight: 500
	text-decoration: none
	color: common.$color-white
	transition: background-color 0.2s ease-in-out
	border: unset

	&.is_variant_primary
		background-color: common.$color-yellow

		&:hover
			background-color: darken(common.$color-yellow, 5%)

	&.is_variant_secondary
		background-color: common.$color-grey-3

		&:hover
			background-color: darken(common.$color-grey-3, 5%)

	&.is_size_default
		font-size: 1.5rem
		padding: math.div(2em, 3) 1.5em
		border-radius: 1.25rem

	&.is_size_small
		font-size: 1rem
		padding: math.div(1em, 3) 0.5em
		border-radius: .625rem
