@use 'common'

$itemSpacing: .625rem
$wrapperMobileHorizontalSpacing: .625rem
$wrapperMobileVerticalSpacing: 1.875rem

.wrapper
	display: flex
	border-radius: .625rem
	overflow: hidden

	&.is_desktop
		align-items: center
		color: common.$color-white

		.list
			gap: 2rem

		.item
			margin: -$itemSpacing

		.appList
			margin-left: 1.5625rem

		@media not all and (min-width: common.$break48)
			display: none

	&.is_mobile
		flex-direction: column
		color: common.$color-black
		background-color: common.$color-white
		border-radius: 0.625rem
		overflow: hidden
		padding: $wrapperMobileVerticalSpacing $wrapperMobileHorizontalSpacing

		.list
			align-items: center
			flex-direction: column
			gap: 1rem

		.appList
			margin-top: 2rem
			justify-content: center

		@media (min-width: common.$break48)
			display: none

.close
	position: absolute
	top: $wrapperMobileVerticalSpacing
	right: $wrapperMobileVerticalSpacing
	background-color: transparent
	padding: .625rem
	border: unset
	background: unset

	&Line
		position: absolute
		top: 50%
		left: 0
		width: 100%
		height: 1px
		background-color: common.$color-black

		&:first-child
			transform: translateY(-50%) rotate(-45deg)

		&:last-child
			transform: translateY(-50%) rotate(45deg)

.list
	list-style-type: none
	padding: unset
	margin: unset
	display: flex

.item
	//

.link
	display: inline-block
	padding: $itemSpacing
	text-decoration: unset
	color: inherit
	font-size: 1.125em

	&.is_active
		font-weight: 700

.app
	&List
		display: flex
		list-style-type: none
		gap: .6875rem
		padding: unset
		margin: unset
		font-size: 2.1875rem

	&Item
		filter: drop-shadow(0 4px 8px 0 rgba(0, 0, 0, 0.2))
