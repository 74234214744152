@use 'common'

.wrapper
	//

.main
	display: flex
	flex-direction: column
	gap: 2.5rem

	@media (min-width: common.$break30)
		flex-direction: initial
		align-items: center
		justify-content: space-between

	@media (min-width: common.$break62)
		gap: 6.25rem

	&.is_isAsideMediaContentMobileLayout
		.column
			display: flex
			justify-content: center
			gap: 1.25rem

			@media (min-width: common.$break30)
				align-self: flex-end

		.socials
			@media not all and (min-width: common.$break30)
				flex-direction: column
				flex-basis: 40%

		.image
			align-self: flex-end
			border-radius: initial

	&.is_layout_default
		max-width: var(--common-content-width-normal)

	&.is_layout_fullWidth
		max-width: var(--common-content-width-wide)

	&.is_contentAlignment_left
		//

	&.is_contentAlignment_right
		@media (min-width: common.$break30)
			flex-direction: row-reverse

.content
	@media (min-width: common.$break30)
		width: 40%
		max-width: 25rem
		align-self: flex-start

.title
	//

.text
	//

.socials
	display: flex
	justify-content: space-between
	align-items: center
	flex-wrap: wrap
	gap: 1.25rem
	margin-top: 2.5rem
	margin-bottom: 2.5rem

	&.is_mobile
		@media (min-width: common.$break30)
			display: none

	&.is_desktop
		@media not all and (min-width: common.$break30)
			display: none

	@media (max-width: common.$break30) or (min-width: common.$break48)
		padding-inline: 1.25rem

.social
	&Item
		width: 1.875rem
		height: 1.875rem

		img
			width: 100%
			height: 100%

		&Link
			display: flex
			justify-content: center
			width: 100%
			height: 100%

		@media (min-width: common.$break30)
			width: 2.5rem
			height: 2.5rem

		@media (min-width: common.$break48)
			width: 3.125rem
			height: 3.125rem

.actions
	margin-top: 1.25rem

.link
	+common.button

.column
	@media (min-width: common.$break30)
		width: 60%

.image
	overflow: hidden
	@media not all and (min-width: common.$break30)
		justify-content: center

	@media not all and (min-width: common.$break48)
		border-radius: 1.875rem

	@media (min-width: common.$break48)
		border-radius: 3.125rem
