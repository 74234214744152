@use 'common'

$gap: 1.25rem

.wrapper
	//

.title
	font-size: 2.125em
	line-height: 1.1
	text-align: center
	margin-top: 0
	margin-bottom: 1.875rem

	@media (min-width: common.$break30)
		font-size: 3.125em

	@media (min-width: common.$break48)
		margin-top: 0
		margin-bottom: 3.75rem
		font-size: 3.75em

.list
	display: flex
	grid-template-columns: 1fr
	justify-content: center
	flex-wrap: wrap
	gap: $gap

.item

	@media (min-width: common.$break30)
		width: calc(50% - ($gap / 2))

	@media (min-width: common.$break48)
		max-width: 21.875rem
