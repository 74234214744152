@use 'common'
@use 'sass:color'

.wrapper
	a
		font-weight: 600
		color: common.$color-text
		transition: text-decoration-color 0.2s

		&:hover, &:focus-visible
			text-decoration-color: #000

	h1
		+common.heading()

	h2
		+common.heading(secondary)

	h3
		+common.heading(tertiary)

	h4
		+common.heading(quaternary)

	h1, h2, h3, h4
		margin-block: unset

	p
		margin-block: unset
		font-size: var(--content-fontSize)

	ul, ol

		& > li
			font-size: var(--content-fontSize)

	em
		font-style: normal
		color: common.$color-text

	[data-contember-align="center"]
		text-align: center

	[data-contember-align="end"]
		text-align: end
