@use 'common'

.wrapper
	--AreaListBlock-gap: 1.25rem

	@media (min-width: common.$break30)
		--AreaListBlock-gap: 2.5rem

	@media (min-width: common.$break62)
		--AreaListBlock-gap: 3.75rem

	@media (min-width: common.$break75)
		--AreaListBlock-gap: 6.25rem

.title
	font-size: 1.875em
	line-height: 1.1
	text-align: center
	margin-block: unset

.list
	list-style-type: none
	padding: unset
	margin: unset
	display: flex
	flex-wrap: wrap
	justify-content: center
	margin-top: 2.5rem
	gap: var(--AreaListBlock-gap)
	row-gap: 2.5rem

.item
	flex-basis: calc(50% - (var(--AreaListBlock-gap) / 2))

	@media (min-width: common.$break37)
		flex-basis: calc(30% - (var(--AreaListBlock-gap)))

	@media (min-width: common.$break62)
		flex-basis: initial
