=heading($type: "primary")

    @if ($type == "primary")
        font-size: 3em

    @else if ($type == "secondary")
        font-size: 2.25em

    @else if ($type == "tertiary")
        font-size: 1.5em
        line-height: 1.0416

    @else if ($type == "quaternary")
        font-size: 1.25em
