@use 'common'

$spacingBlock: .9375rem

.wrapper
	color: common.$color-black

	background-color: var(--AppBanner-color, common.$color-white)
	font-weight: 500
	border-radius: 1.875rem
	overflow: hidden
	transform: translateZ(0)

	&.is_default
		padding: $spacingBlock .625rem 0 .9375rem

		.links
			flex-direction: column
			margin-bottom: $spacingBlock

	&.is_video
		padding: $spacingBlock

		.links
			justify-content: center

	&.is_vertical
		max-width: 16.875rem

	&.is_vertical, &.is_horizontal

		.content
			grid-template-columns: 1fr 1fr
			gap: .625rem
			row-gap: .3125rem

		.title
			font-size: 1.5em
			line-height: 1.25
			grid-column: 1 / -1

		.text
			p
				font-size: .9375em
				line-height: 1.2

		.image
			justify-content: flex-end

	&.is_horizontal

		@media (min-width: common.$break37)
			padding: 1.5rem .875rem 0 1.75rem

		.content
			@media (min-width: 22.5rem)
				grid-template-columns: 1fr auto
				grid-template-rows: auto 1fr
				gap: 1.25rem
				row-gap: .625rem

		.title

			@media (min-width: 22.5rem)
				grid-column: 1

			@media (min-width: common.$break30)
				font-size: 1.875em
				line-height: 1.026

			@media (min-width: common.$break37)
				font-size: 2.4375em

		.text
			grid-column: 1

			p
				@media (min-width: common.$break30)
					font-size: 1.125em
					line-height: 1.25

				@media (min-width: common.$break37)
					font-size: 1.5em

		.image
			grid-column: 2

			@media (min-width: 22.5rem)
				grid-row: 1 / 3

		.links
			@media (min-width: 26.25rem)
				flex-direction: initial
				gap: .875rem

		.link

			@media (min-width: common.$break37)
				font-size: 3rem

.content
	display: grid

	&In
		//

.title
	font-weight: inherit
	margin-block: unset
	color: inherit

.text
	p
		margin-top: unset

.links
	display: flex
	gap: .625rem
	row-gap: .3125rem

.link
	display: flex
	font-size: 2.375rem

	@media (min-width: 23rem)
		font-size: 2.8125rem

	@media (min-width: common.$break75)
		font-size: 2rem

.image
	width: 100%
	display: flex
	align-self: flex-end
	z-index: 0

.video
	&Wrapper
		//

	&Main
		border-radius: 1.25rem
		overflow: hidden
		transform: translateZ(0)
		aspect-ratio: 1 / 2
		width: 100%
		object-fit: cover
		background-color: common.$color-grey

	&In
		position: absolute
		inset: 0
		object-fit: cover
		width: 100%
		height: 100%
		z-index: 0

.appButtons
	margin-top: .625rem

.tileImage
	z-index: 0
