@use 'common'

.wrapper
	//

.list
	//

.item
	margin-top: .625rem
