@use 'common'

.wrapper
    display: flex
    justify-content: center
    align-items: center
    border-radius: .25rem
    padding: 1.25rem

    &.is_success
        color: common.$color-success
        background-color: common.$color-success-background
        border: 1px solid common.$color-success-border

    &.is_error
        color: common.$color-error
        background-color: common.$color-error-background
        border: 1px solid common.$color-error-border

.message
    margin: unset
