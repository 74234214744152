@use 'common'

.wrapper
    //

.message
    position: fixed
    top: common.$header-height
    left: 0
    right: 0
    width: 100%
    max-width: 37.5rem
    margin-inline: auto
    padding-inline: var(--common-edge-gap)
    visibility: hidden
    opacity: 0
    transition-property: visibility, opacity
    transition-duration: 0.3s
    z-index: 1

    &.is_visible
        visibility: initial
        opacity: 1

.fields
    border: 2px solid common.$color-white
    background-color: common.$color-grey

.item

    &:not(:first-child)
        border-top: 2px solid common.$color-white

.submit
    margin-top: .9375rem
