@use 'common'

=hiddenStyles
	visibility: hidden
	opacity: 0
	transition-property: all
	transition-duration: .2s

=visibleStyles
	visibility: initial
	opacity: 1

.wrapper
	position: relative
	padding-top: 1.875rem
	padding-bottom: 2.8125rem

	&.is_mobileOpen
		.navigation
			&Mobile
				+visibleStyles

.main
	display: flex
	justify-content: space-between
	align-items: center

.logo
	min-width: 10.625rem
	width: max-content

	&Link
		display: block

.navigation

	.is_desktop
		@media not all and (min-width: common.$break48)
			display: none

	.is_mobile
		@media (min-width: common.$break48)
			display: none

	&Mobile
		+hiddenStyles
		position: absolute
		left: 0
		right: 0
		top: var(--common-edge-gap)
		margin-inline: var(--common-edge-gap)
		z-index: 10

.hamburger
	display: flex
	gap: .625rem
	align-items: center
	background-color: transparent
	border: unset
	cursor: pointer
	padding: .4375rem .9375rem
	border-radius: 5rem

	&Text
		line-height: 1
		font-weight: 500
		font-family: var(--font-body)
		font-size: 1.25em

.lines
	display: flex
	flex-direction: column
	justify-content: space-between
	width: 1.5625rem
	height: 1.1875rem

.line
	background-color: #FFFFFF
	height: .1875rem
	width: 100%
	top: 50%
	right: 0
	z-index: 0
