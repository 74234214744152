@use 'common'

.wrapper
	//

.main
	--AccordionItem-block-spacing: .9375rem
	--AccordionItem-inline-spacing: 1.25rem

	border: 1px solid common.$color-white
	border-radius: 1.0625rem

	@media (min-width: common.$break30)
		--AccordionItem-block-spacing: 1.5625rem
		--AccordionItem-inline-spacing: 1.875rem

.header
	//

.name
	+common.heading(tertiary)
	margin-block: unset

.button
	width: 100%
	display: flex
	justify-content: space-between
	gap: 1.25rem
	background: unset
	border: unset
	padding: var(--AccordionItem-block-spacing) var(--AccordionItem-inline-spacing)
	font-family: inherit
	font-weight: 600
	text-align: left
	color: inherit

.mark
	$size: 1.5rem

	display: flex
	position: relative
	width: $size
	height: $size
	font-size: $size

	.is_plus,
	.is_minus
		position: absolute
		inset: 0
		opacity: 0
		visibility: hidden
		transition-property: visibility, opacity
		transition-duration: .2s

	.is_visible
		opacity: 1
		visibility: initial

.body
	padding: 0 var(--AccordionItem-inline-spacing) var(--AccordionItem-block-spacing)

	p
		font-size: var(--content-fontSize)
