@use 'common'

$tileSpacing: 1.25rem

.wrapper
	height: 100%
	padding: $tileSpacing
	background-color: common.$color-grey
	border-radius: .625rem

.header
	display: flex
	column-gap: 1.25rem

	@media (min-width: common.$break22)

	@media (min-width: common.$break30)
		flex-direction: column

.number
	text-align: center
	font-size: 6.25rem
	font-weight: 700
	line-height: 0.8

	.is_desktop
		-webkit-text-stroke: 1px common.$color-white
		text-stroke: 1px common.$color-white
		color: transparent
		font-size: 8.125rem

		@media not all and (min-width: common.$break30)
			display: none

	.is_mobile
		@media (min-width: common.$break30)
			display: none

	@media (min-width: common.$break30)
		$numberVerticalSpacing: 2.5rem

		padding-block: ($numberVerticalSpacing - $tileSpacing) $numberVerticalSpacing

.title
	font-size: 1.5rem
	line-height: 1.23
	margin: unset

	@media (min-width: common.$break30)
		font-size: 1.875rem

.text
	p
		font-size: 1.25em
		line-height: 1.25
		margin-block: 1rem 0
